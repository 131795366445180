<template>
  <div class="readme-article">
    <div id="财务"></div>
    <h1 id="学生缴费">
      一、学生缴费
    </h1>
    <h2 id="财务流水">1.财务流水</h2>
    <h3 id="财务流水列表">1.1财务流水列表</h3>
    <p>查看财务流水列表，展示财务流水基本信息，可点击按钮查看详情以及打印收费单。</p>
    <p>
      <img src="@/assets/img/finance/1-1.png">
    </p>
    <h3 id="财务流水详情">1.2财务流水详情</h3>
    <p>财务流水详情展示，管理员可编辑流水信息。</p>
    <p>
      <img src="@/assets/img/finance/1-2.png">
    </p>
    <h2 id="续费-缴费">2.续费-缴费</h2>
    <h3 id="续费-缴费列表">2.1续费-缴费列表</h3>
    <p>查看学员列表，展示学员财务信息，包含余额、剩余课时等信息，点击操作按钮可进行续费/缴费。</p>
    <p>
      <img src="@/assets/img/finance/1-3.png">
    </p>
    <h3 id="续费-缴费提交">2.2续费-缴费提交</h3>
    <p>管理员可对学员进行续费/缴费操作，需填写收据编号、协议编号、协议金额和业绩归属，付款金额不能等于0。</p>
    <p>
      <img src="@/assets/img/finance/1-4.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Finance1-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>